import styled from 'styled-components';

import { colors } from './styles';
import rycoLogo from './ryco.png';

export const Spinner = styled.div`
  border: 10px solid ${colors.white};
  border-top: 10px solid ${colors.rycoPurple};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const MaintainceModeHeader = styled.h1`
  color: ${colors.white};
  text-align: center;
  padding: 0 20px;
`;

export const MaintainceLogo = styled.img.attrs({
  src: rycoLogo,
  alt: 'Ryco Logo',
})`
  width: 150px;
  height: 150px;
`;
