import styled from 'styled-components';

import { colors } from '../../../styles';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-color: ${colors.white};
`;

export const Header = styled.h1`
  color: ${colors.lightPurple};
  text-align: center;
`;

export const SubHeader = styled.p`
  color: ${colors.black};
  text-align: center;
  margin-bottom: auto;
  max-width: 380px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Input = styled.input`
  width: 75%;
  border: 2px solid ${colors.lightPurple};
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 25px;
  margin-top: 25px;
`;

export const SubmitButton = styled.button`
  background-color: ${colors.lightPurple};
  color: ${colors.white};
  border: 1px solid ${colors.white};
  border-radius: 15px;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: ${colors.darkPurple};
  }
`;

export const CancelButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${colors.lightPurple};
  text-decoration: underline;
  padding: 0;
  font-size: inherit;

  &:hover {
    color: ${colors.darkPurple};
  }
`;
