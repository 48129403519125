import styled from 'styled-components';

import { colors } from '../../../styles';

export const Button = styled.button`
  color: ${colors.white};
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  width: 100%;
  font-size: 14px;
  background: ${colors.rycoPurple};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 15vw;
  height: 55px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
