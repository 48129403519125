import styled from 'styled-components';
import { default as UnstyledTextAreaAutosize } from 'react-textarea-autosize';

import { colors } from '../../styles';
import rycoLogo from '../../ryco.png';

const trialHeaderHeight = '55px';

export const InputWrapper = styled.div`
  display: flex;
  padding: 20px;
  position: relative;

  align-items: center;

  @media (max-width: 768px) {
    position: fixed;
    width: calc(100% - 40px);
    bottom: 0;
    background-color: ${colors.white};
  }
`;

export const ChatWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;

  @media (max-width: 768px) {
    height: calc(100% - 160px);
    margin-top: 50px;
  }
`;

export const MessageWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: ${({ $index }) => ($index === 0 ? '10px' : '0px')};
`;

export const ChatContainer = styled.div`
  width: 100%;
  height: ${({ hasHeader }) =>
    hasHeader ? `calc(100vh - ${trialHeaderHeight})` : '100vh'};
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

export const BackgroundRycoLogo = styled.img.attrs({
  src: rycoLogo,
  alt: 'ryco logo',
})`
  width: 10vw;
  margin-top: 40vh;
  margin-bottom: auto;
  align-self: center;
  justify-self: center;
  opacity: 0.5;
`;

export const TextareaAutosize = styled(UnstyledTextAreaAutosize).attrs({
  rows: '1',
  placeholder: 'Message rybot...',
})`
  width: 100%;
  border-radius: 15px;
  border-color: ${colors.lightPurple};
  padding: 20px;
  outline: none;
  resize: none;
  font-size: 14px;
  padding-right: 65px;
`;

export const TrialHeader = styled.div`
  height: ${trialHeaderHeight};
  background: ${colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  color: ${colors.black};

  @media (max-width: 768px) {
    margin-top: 50px;
    flex-direction: column;
    font-size: 14px;
  }
`;
