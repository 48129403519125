import styled from 'styled-components';
import { colors } from '../../styles';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(1px);
`;

export const Modal = styled.div`
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    ${colors.rycoPurple} 0vh,
    ${colors.lightPurple} 50vh
  );
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 101;

  @media (max-width: 1024px) {
    width: 60%;
    height: 50%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

export const CloseButton = styled.button`
  border: 2px solid ${colors.white};
  background: none;
  color: ${colors.white};
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    transform: scale(1.05);
  }
`;

export const Header = styled.h1`
  color: ${colors.white};
`;

export const Description = styled.p`
  max-width: 80%;
  text-align: center;
  color: ${colors.white};
  font-size: 1.2rem;
`;

export const UpgradeButton = styled(CloseButton)`
  margin-right: 20px;
  background-color: ${colors.white};
  color: ${colors.lightPurple};
  text-decoration: none;
`;
