import React from 'react';
import { doc, setDoc } from 'firebase/firestore';

import {
  Wrapper,
  Container,
  Header,
  SubHeader,
  Form,
  Input,
  SubmitButton,
  CancelButton,
} from './styled-components';

const WaitList = ({ setJoinWaitlist, db }) => {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      try {
        setDoc(
          doc(db, 'waitlist', email),
          {
            email: email,
            timestamp: new Date(),
          },
          { merge: true }
        ).then(() => {
          window.alert(
            'Thank you for joining our waitlist, we will notify you when you have access to the beta!'
          );
          setEmail('');
          setJoinWaitlist(false);
        });
      } catch (error) {
        window.alert('Something went wrong, please reach out for support');
      }
    } else {
      window.alert('Please enter a valid email address');
    }
  };

  return (
    <Wrapper>
      <Container>
        <Header>Join our waitlist</Header>
        <SubHeader>
          Please enter your email address below and we will notify you when
          you're added to the beta.
        </SubHeader>
        <Form>
          <Input
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <SubmitButton type="submit" onClick={handleSubmit}>
            Submit
          </SubmitButton>
          <CancelButton
            onClick={() => {
              setJoinWaitlist(false);
            }}
          >
            Cancel
          </CancelButton>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default WaitList;
