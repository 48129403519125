import React from 'react';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';

import NewThreadButton from './NewThreadButton';
import MobileMenu from './MobileMenu';
import SettingsMenu from './SettingsMenu';

import Chat from '../Chat';

import {
  Button,
  ChatContainer,
  SideBar,
  Wrapper,
  ChatPreview,
} from './styled-components';

const ChatsManager = React.memo(({ auth, db }) => {
  const [selectedChatIndex, setSelectedChatIndex] = React.useState(0);
  const [chats, setChats] = React.useState([]);
  const [highestChatIndex, setHighestChatIndex] = React.useState(0);
  const [hasSentMessage, setHasSentMessage] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [counter, setCounter] = React.useState(15);

  React.useEffect(() => {
    const unsub = onSnapshot(
      doc(db, auth.currentUser.email, 'chatIndexes'),
      (newDoc) => {
        const chatsData = newDoc.data();
        //legacy data transformation
        if (!chatsData) {
          setUpdating(true);
          onSnapshot(doc(db, auth.currentUser.email, 'chats'), (oldDoc) => {
            const chatsData = oldDoc.data();
            if (chatsData) {
              const keys = Object.keys(chatsData);
              const newDataShape = keys.map((key, idx) => {
                const arryOfObj = chatsData[key];
                //transform array of objects into object of objects
                const newChats = arryOfObj.reduce((acc, curr, idx) => {
                  return { ...acc, [idx]: curr };
                }, {});

                setDoc(doc(db, auth.currentUser.email, key), newChats);
                setHighestChatIndex(idx);

                return {
                  //get the first 30 characters of the last user sent message in each thread
                  [key]: chatsData[key][
                    chatsData[key].length - 2
                  ].content.slice(0, 100),
                };
              });

              //flatten array of objects into single object
              const newChats = Object.assign({}, ...newDataShape);

              setDoc(doc(db, auth.currentUser.email, 'chatIndexes'), newChats);
            }
          });
        } else {
          setChats(chatsData);
          setHighestChatIndex(Object.keys(chatsData).length - 1);
        }
      }
    );

    return () => unsub();
  }, [db, auth.currentUser.email]);

  React.useEffect(() => {
    if (!updating) return;
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setUpdating(false);
    }

    return () => clearInterval(timer);
  }, [counter, updating]);

  if (updating) {
    return (
      <h1
        style={{
          color: 'white',
          textAlign: 'center',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        Updating you to the latest version...
        <br />
        <br />
        <div className="loader"></div>
        <br />
        Please wait for {counter} seconds.
      </h1>
    );
  }

  return (
    <Wrapper>
      <SideBar>
        <NewThreadButton
          setSelectedChatIndex={setSelectedChatIndex}
          setHasSentMessage={setHasSentMessage}
          setHighestChatIndex={setHighestChatIndex}
          highestChatIndex={highestChatIndex}
          db={db}
          auth={auth}
        />

        {Object.keys(chats).map((thread, idx) => (
          <Button
            $isActive={selectedChatIndex === thread}
            key={thread}
            onClick={() => setSelectedChatIndex(thread)}
          >
            <ChatPreview>{chats[thread]}</ChatPreview>
          </Button>
        ))}
        <SettingsMenu auth={auth} />
      </SideBar>
      <MobileMenu
        selectedChatIndex={selectedChatIndex}
        setSelectedChatIndex={setSelectedChatIndex}
        highestChatIndex={highestChatIndex}
        chats={chats}
        auth={auth}
      />
      <ChatContainer>
        <Chat
          db={db}
          auth={auth}
          index={selectedChatIndex}
          hasSentMessage={hasSentMessage}
          setHasSentMessage={setHasSentMessage}
        />
      </ChatContainer>
    </Wrapper>
  );
});

export default ChatsManager;
