import styled from 'styled-components';

import { colors } from '../../../styles';

export const ChatBubble = styled.div`
  border: 2px solid
    ${({ $role }) =>
      $role === 'user' ? `${colors.black}` : `${colors.lightPurple}`};
  border-radius: 15px;
  padding: 10px;
`;
