import React from 'react';

import { useLocalStorage } from 'usehooks-ts';

import {
  Wrapper,
  Modal,
  CloseButton,
  ProfileImage,
  Header,
  Description,
  UpgradeButton,
} from './styled-components';

const LimitReached = ({ name, photoURL, trial }) => {
  const [open, setOpen] = useLocalStorage('trialModalOpen', true);

  if (!open) {
    return null;
  }

  return (
    <Wrapper>
      <Modal>
        <ProfileImage src={photoURL} alt="current user" />
        <Header>👋 Hello {name} 👋</Header>
        <Description>
          Welcome to rybot! Your account is currently on a trial. You have{' '}
          {trial} requests left and each request will be limited to 250 words.
          Please contact us to upgrade your account.
        </Description>
        <div>
          {/* <UpgradeButton onClick={() => setOpen(false)}>Upgrade</UpgradeButton> */}
          <UpgradeButton as="a" href="mailto:rybot@ryco.io">
            Contact
          </UpgradeButton>
          <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default LimitReached;
