import styled from 'styled-components';

import { colors } from '../../../styles';

export const Button = styled.button`
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.2)' : 'none'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const MobileNav = styled.div`
  height: 50px;
  width: 100vw;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    ${colors.darkPurple},
    ${colors.lightPurple} 100vh
  );

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileNavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
