import styled from 'styled-components';

import { colors } from '../../../styles';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 15vw;
  background: ${colors.lightPurple};
  height: 55px;
`;

export const ProfileImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const UserName = styled.p`
  margin: 0;
  color: white;
  font-size: 14px;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 0.5vw;
  width: 14vw;
  background: ${colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const LogoutLabel = styled.p`
  margin: 0px;
  margin-left: 10px;
`;
