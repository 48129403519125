import styled from 'styled-components';

import { colors } from '../../styles';

export const Button = styled.button`
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.2)' : 'none'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const SideBar = styled.div`
  width: 15vw;
  background: linear-gradient(
    180deg,
    ${colors.darkPurple},
    ${colors.lightPurple} 100vh
  );
  overflow-y: scroll;
  padding: 55px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ChatContainer = styled.div`
  width: 85vw;
  height: 100vh;

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChatPreview = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  margin: 0;
`;
