import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { sanitizeMessageContent, hasHTML } from './utils';
import { ChatBubble } from './styled-components';

const MessageContent = React.memo(({ role, content }) => {
  if (role === 'user') {
    return <ChatBubble $role={role}>{content}</ChatBubble>;
  } else {
    if (hasHTML(content)) {
      return (
        <ChatBubble
          $role={role}
          dangerouslySetInnerHTML={{
            __html: sanitizeMessageContent(content),
          }}
        />
      );
    } else {
      return (
        <ChatBubble $role={role}>
          <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
        </ChatBubble>
      );
    }
  }
});

export default MessageContent;
