import React from 'react';
import { signOut } from 'firebase/auth';

import rycoLogo from '../../../ryco.png';
import { colors } from '../../../styles';
import { MobileNavWrapper, MobileNav, Button } from './styled-components';

import './HamburgerMenu.css';

const MobileMenu = ({
  selectedChatIndex,
  setSelectedChatIndex,
  highestChatIndex,
  chats,
  auth,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <MobileNav>
      <img
        src={rycoLogo}
        alt="Ryco Logo"
        style={{ width: '35px', height: 35, marginLeft: '10px' }}
      />
      <MobileNavWrapper>
        <input
          id="toggle"
          type="checkbox"
          onChange={(e) => setIsOpen(e.target.checked)}
          checked={isOpen}
        ></input>
        <label
          htmlFor="toggle"
          className="hamburger"
          style={
            isOpen
              ? { position: 'absolute', zIndex: 15, top: 0, right: 10 }
              : {
                  position: 'inherit',
                  marginRight: '10px',
                  marginBottom: '10px',
                  display: 'block',
                }
          }
        >
          <div className="top-bun"></div>
          <div className="meat"></div>
          <div className="bottom-bun"></div>
        </label>

        <div className="nav">
          <div className="nav-wrapper">
            <div
              style={{
                position: 'fixed',
                top: 0,
                paddingTop: '10px',
                backgroundColor: colors.darkPurple,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                onClick={() => {
                  setSelectedChatIndex(highestChatIndex + 1);
                  setIsOpen(false);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  justifyContent: 'center',
                  border: '1px solid white',
                  width: '50%',
                  borderRadius: '15px',
                  background: colors.white,
                  color: colors.black,
                }}
              >
                <img
                  src={rycoLogo}
                  alt="Ryco Logo"
                  style={{ width: '35px', marginRight: '10px' }}
                />
                New Thread
                <svg
                  style={{ marginLeft: '10px' }}
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-md"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Button>
              <div
                style={{ textAlign: 'center', margin: 0, marginTop: '25px' }}
              >
                <strong style={{ color: colors.white }}>Threads</strong>
                <div
                  style={{
                    marginTop: '10px',
                    width: '100vw',
                    height: 2,
                    background: colors.white,
                  }}
                />
              </div>
            </div>

            {Object.keys(chats).map((thread) => {
              return (
                <Button
                  $isActive={selectedChatIndex === thread.index}
                  key={thread}
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedChatIndex(thread);
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      margin: 0,
                    }}
                  >
                    {chats[thread]}
                  </p>
                </Button>
              );
            })}
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                backgroundColor: colors.white,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                style={{
                  alignItems: 'center',
                  border: 'none',
                  width: 'fit-content',
                  color: colors.darkPurple,
                }}
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch((error) => {
                      window.alert(
                        'Something went wrong, please reach out for support'
                      );
                    });
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-md"
                  style={{ marginRight: '10px' }}
                >
                  <path
                    d="M11 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H11"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M20 12H11M20 12L16 16M20 12L16 8"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <p style={{ margin: 0 }}>Logout</p>
              </Button>
            </div>
          </div>
        </div>
      </MobileNavWrapper>
    </MobileNav>
  );
};

export default MobileMenu;
