import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

const FileUpload = forwardRef((props, ref) => {
  const [fileInputValue, setFileInputValue] = useState('');
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      props.onFileSelect(e); // Call parent's method to handle file selection
      setFileInputValue(e.target.value); // Update local state to reflect the file input's value
    }
  };

  const resetFileInput = () => {
    setFileInputValue(''); // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input element
    }
  };

  useImperativeHandle(ref, () => ({
    resetFileInput,
  }));

  // Function to trigger the file input click
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        value={fileInputValue}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
        accept=".txt,.pdf,.docx,.xlsx,.xls,.csv" // Specify acceptable file types
        onClick={(e) => (e.target.value = null)} // Ensure the input can accept the same file again if needed
      />
      <button
        aria-label="Upload File"
        onClick={triggerFileInput}
        // add a label on hover
        title="Upload File"
        style={{
          display: 'block',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            fill="#C205FF"
            fill-rule="evenodd"
            d="M9 7a5 5 0 0 1 10 0v8a7 7 0 1 1-14 0V9a1 1 0 0 1 2 0v6a5 5 0 0 0 10 0V7a3 3 0 1 0-6 0v8a1 1 0 1 0 2 0V9a1 1 0 1 1 2 0v6a3 3 0 1 1-6 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </>
  );
});

export default FileUpload;
