import styled from 'styled-components';
import { colors } from '../../../styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
`;

export const Image = styled.img.attrs({
  alt: 'profile',
})`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Label = styled.p`
  margin: 0;
  margin-top: 0;
  font-weight: bold;
  color: ${({ $isUser }) => ($isUser ? colors.black : colors.lightPurple)};
`;
