import React, { useState, useRef } from 'react';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';
import axios from 'axios';
import {
  getTokenCost,
  year,
  month,
} from '../../services/TokenService/tokenService';

import { colors } from '../../styles';

import MessageContent from './MessageContent';
import SenderHeader from './SenderHeader';
import SendMessageButton from './SendMessageButton';
import LimitReached from '../LimitReached';
import Trial from '../Trial';
import FileUpload from '../FileUpload/FileUploadComponent';

import {
  ChatContainer,
  ChatWrapper,
  InputWrapper,
  MessageWrapper,
  BackgroundRycoLogo,
  TextareaAutosize,
  TrialHeader,
} from './styled-components';

import { DB_PATH } from '../../services/TokenService/tokenService';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const defaultChatState = [
  {
    role: 'system',
    content:
      "Your name is rybot and you are created by the company ryco.io. Your goal is to assist teachers, the chatbot should focus solely on educational content, appropriateness, and student wellness. Encourage engagement by asking relevant questions about classroom dynamics and student engagement strategies. The chatbot must avoid any unrelated topics, ensuring all advice pertains strictly to the context of education and pedagogical best practices. If grade level or any other needed information isn't included in the original request,  ask for them before answering. To be clear, NEVER send an answer without all the needed information first. Additionally don't promote the use of any other educational resources sites other than https://ryco.io. Please respond in markdown format.",
  },
];

const isRycoUser = (email) => {
  if (email.includes('@ryco.io')) {
    return true;
  }
  return false;
};

const Chat = React.memo(
  ({ auth, db, index, hasSentMessage, setHasSentMessage }) => {
    const [stateChatHistory, setStateChatHistory] = useState(defaultChatState);
    const [answer, setAnswer] = useState('');
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [userValue, setValue] = useState('');
    const [mockUserValue, setMockUserValue] = useState('');
    const chatThreadWrapperRef = React.useRef();
    const [limitReached, setLimitReached] = useState(false);
    const [open, setOpen] = useState(false);
    const [trial, setTrial] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [file, setFile] = useState(null);

    const fileUploadRef = useRef(null);

    const isTrial = trial === 0 ? true : trial;

    const trialWordLimit = 250;

    const filteredMessages = stateChatHistory?.filter(
      (history) => history.role !== 'system'
    );

    const handleFileSelect = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
      }
    };

    const { email, displayName, photoURL } = auth.currentUser;

    const trialBannerText =
      trial === 0 ? (
        <>
          Your trial has ended. You have {trial} requests left.&nbsp;
          <div>
            Please&nbsp;
            <a style={{ color: colors.rycoPurple }} href="mailto:rybot@ryco.io">
              contact us
            </a>
            &nbsp;to upgrade your account.
          </div>
        </>
      ) : (
        <>
          You are currently on a trial. You have {trial}/30 requests left.&nbsp;
          <div>
            Please&nbsp;
            <a style={{ color: colors.rycoPurple }} href="mailto:rybot@ryco.io">
              contact us
            </a>
            &nbsp;to upgrade your account.
          </div>
        </>
      );

    React.useEffect(() => {
      const unsub = onSnapshot(doc(db, email, String(index)), (doc) => {
        if (doc.data()) {
          //convert doc.data from an object of objects to an array of objects
          const docData = doc.data();
          const chatHistory = Object.keys(docData).map((key) => docData[key]);
          setStateChatHistory(chatHistory);
        } else {
          setStateChatHistory(defaultChatState);
        }
      });

      return () => unsub();
    }, [db, index, email]);

    React.useEffect(() => {
      const unsub = onSnapshot(doc(db, DB_PATH, email), (doc) => {
        if (
          doc.data()?.[year]?.[month]?.limitReached &&
          doc.data()?.unlimited === false
        ) {
          setLimitReached(true);
        } else {
          setLimitReached(false);
        }
      });
      return () => unsub();
    }, [db, email]);

    React.useEffect(() => {
      const unsub = onSnapshot(doc(db, DB_PATH, email), (doc) => {
        if (
          !doc.data()?.isPaidUser &&
          !doc.data()?.unlimited &&
          (doc.data()?.trialRequests || doc.data()?.trialRequests === 0)
        ) {
          setTrial(doc.data()?.trialRequests);
        }
      });
      return () => unsub();
    }, [db, email]);

    const setChatHistory = async (arrToAdd) => {
      const chatHistory = [...stateChatHistory, ...arrToAdd];

      try {
        //transform array of objects to object of objects
        const chatHistoryObj = chatHistory.reduce((acc, curr, idx) => {
          return { ...acc, [idx]: curr };
        }, {});

        await setDoc(doc(db, email, String(index)), chatHistoryObj);
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    };

    const handleSendMessage = async (e) => {
      if (!e || e.key === 'Enter') {
        if (limitReached) {
          setOpen(true);
          return;
        }

        if (userValue || file) {
          setWordCount(0);
          setHasSentMessage(true);

          setDoc(
            doc(db, email, 'chatIndexes'),
            {
              [index]: file ? 'File uploaded' : userValue.slice(0, 100),
            },
            { merge: true }
          );

          //scroll to bottom
          if (chatThreadWrapperRef?.current?.scrollTop) {
            chatThreadWrapperRef.current.scrollTop =
              chatThreadWrapperRef?.current?.scrollHeight;
          }

          setMockUserValue(file ? 'processing file upload...' : userValue);
          setValue('');

          const formData = new FormData();

          if (file) {
            formData.append('file', file);
          }

          const updatedChatHistory = [
            ...stateChatHistory,
            {
              role: 'user',
              content: userValue,
            },
          ];

          const last9Messages = updatedChatHistory.slice(-9);

          const modifiedChatHistory = [...defaultChatState, ...last9Messages];

          let response;

          if (file) {
            response = await axios.post(ENDPOINT, formData);
            setChatHistory([...stateChatHistory, ...response.data.chatHistory]);

            setFile(null);

            setMockUserValue('');

            if (fileUploadRef.current) {
              fileUploadRef.current.resetFileInput();
            }
            return;
          } else {
            response = await fetch(ENDPOINT, {
              method: 'post',
              headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                chatHistory:
                  updatedChatHistory.length > 10
                    ? modifiedChatHistory
                    : updatedChatHistory,
              }),
            });
          }

          if (!response.ok || !response.body) {
            throw response.statusText;
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;

          while (loopRunner) {
            const localCopyOfUserQuestion = userValue;
            setIsBotTyping(true);
            const { value, done } = await reader.read();

            if (done) {
              setIsBotTyping(false);
              setAnswer((answer) => {
                //reduce state chat history to a single string of the content of each item in the array

                const last9Messages = stateChatHistory.slice(-9);

                const modifiedChatHistory = [
                  ...defaultChatState,
                  ...last9Messages,
                ];

                const stateChatHistoryContent = (
                  stateChatHistory.length > 10
                    ? modifiedChatHistory
                    : stateChatHistory
                ).reduce((acc, curr) => {
                  return acc + curr.content;
                }, '');

                getTokenCost(
                  { response: answer, request: stateChatHistoryContent },
                  db,
                  email
                );
                setChatHistory([
                  {
                    role: 'user',
                    content: localCopyOfUserQuestion,
                  },
                  {
                    role: 'assistant',
                    content: answer,
                  },
                ]);
                return '';
              });
              setMockUserValue('');
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            setAnswer((answer) => answer + decodedChunk);
          }
        } else {
          return;
        }
      }
    };

    const setChange = React.useCallback(
      (e) => {
        const text = e.target.value;
        if (!isTrial) {
          setValue(text);
          return;
        }
        let words = text.split(' ').filter(Boolean);
        if (words.length > trialWordLimit) {
          return;
        } else {
          setValue(text);
          setWordCount(words.length);
        }
      },
      [trialWordLimit, isTrial]
    );

    return (
      <>
        {limitReached && (
          <LimitReached
            name={displayName}
            open={open}
            setOpen={setOpen}
            photoURL={photoURL}
            isTrial={isTrial}
          />
        )}
        {isTrial && (
          <Trial name={displayName} trial={trial} photoURL={photoURL} />
        )}
        {isTrial && <TrialHeader>{trialBannerText}</TrialHeader>}
        <ChatContainer hasHeader={isTrial}>
          {!hasSentMessage && stateChatHistory?.length === 1 ? (
            <BackgroundRycoLogo />
          ) : (
            <ChatWrapper ref={chatThreadWrapperRef}>
              <div>
                {filteredMessages.map((message, index) => {
                  const { role, content } = message;
                  return (
                    <MessageWrapper key={index} $index={index}>
                      <SenderHeader
                        role={role}
                        displayName={displayName}
                        photoURL={photoURL}
                      />
                      <MessageContent role={role} content={content} />
                    </MessageWrapper>
                  );
                })}
                {mockUserValue && (
                  <MessageWrapper>
                    <SenderHeader
                      role={'user'}
                      displayName={displayName}
                      photoURL={photoURL}
                    />
                    <MessageContent role={'user'} content={mockUserValue} />
                  </MessageWrapper>
                )}
                {answer && (
                  <MessageWrapper>
                    <SenderHeader
                      role={'assistant'}
                      displayName={displayName}
                      photoURL={photoURL}
                    />
                    <MessageContent role={'assistant'} content={answer} />
                  </MessageWrapper>
                )}
              </div>
            </ChatWrapper>
          )}
          <InputWrapper>
            {isRycoUser(email) && (
              <FileUpload onFileSelect={handleFileSelect} ref={fileUploadRef} />
            )}
            {isTrial && (
              <p style={{ color: colors.lightPurple, textAlign: 'center' }}>
                {wordCount}/250 words
              </p>
            )}

            <TextareaAutosize
              disabled={file}
              onChange={(e) => {
                setChange(e);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage(e);
                }
              }}
              value={file ? 'File uploaded' : userValue}
            />

            <SendMessageButton
              disabled={isBotTyping}
              onClick={() => {
                handleSendMessage();
              }}
            />
          </InputWrapper>
        </ChatContainer>
      </>
    );
  }
);

export default Chat;
