import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import WaitList from './WaitList';

import {
  Wrapper,
  Container,
  Logo,
  Header,
  SubHeader,
  LoginButton,
  WaitListButton,
} from './styled-components';

import GoogleLoginSvg from './GoogleLoginSvg';

const Login = ({ auth, db }) => {
  const [joinWaitlist, setJoinWaitlist] = React.useState(false);
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {})
      .catch((error) => {
        window.alert('Something went wrong, please reach out for support');
      });
  };

  return (
    <Wrapper>
      {joinWaitlist ? (
        <WaitList setJoinWaitlist={setJoinWaitlist} db={db} />
      ) : (
        <Container>
          <Logo />
          <Header>Welcome to ryco bot beta</Header>
          <SubHeader>
            To confirm access to the site please authenticate with Google using
            the button below
          </SubHeader>
          <LoginButton onClick={signInWithGoogle}>
            <GoogleLoginSvg />
          </LoginButton>
          <p>
            If you don't have access{' '}
            <WaitListButton
              onClick={() => {
                setJoinWaitlist(true);
              }}
            >
              join our waitlist
            </WaitListButton>
            .
          </p>
        </Container>
      )}
    </Wrapper>
  );
};

export default Login;
