import styled from 'styled-components';
import { colors } from '../../../styles';

export const Button = styled.button`
  background-color: ${colors.lightPurple};
  color: ${colors.white};
  border: none;
  border-radius: 15px;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
