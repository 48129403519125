export const sanitizeMessageContent = (content) => {
  const itemsToRemove = ['null', '```html', '<html>', '</html>', '```'];

  let newContent = content;

  itemsToRemove.forEach((item) => {
    newContent = newContent.replace(item, '');
  });

  return newContent;
};

export const hasHTML = (str) => {
  return /<[a-z][\s\S]*>/i.test(str);
};
