import React from 'react';
import { signOut } from 'firebase/auth';

import { ReactComponent as LogoutSvg } from './logout.svg';

import {
  Wrapper,
  ProfileImage,
  UserName,
  MenuWrapper,
  LogoutLabel,
} from './styled-components';
import { Button } from '../styled-components';

const SettingsMenu = React.memo(({ auth }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <Wrapper>
      <Button
        style={{ alignItems: 'center' }}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <ProfileImage src={auth.currentUser.photoURL} alt="current user" />
        <UserName>{auth.currentUser.displayName}</UserName>
      </Button>
      {menuOpen && (
        <MenuWrapper>
          <Button
            style={{
              color: 'black',
              alignItems: 'center',
            }}
            onClick={() => {
              signOut(auth)
                .then(() => {
                  window.location.reload();
                })
                .catch((error) => {
                  window.alert(
                    'Something went wrong, please reach out for support'
                  );
                });
            }}
          >
            <LogoutSvg />
            <LogoutLabel>Logout</LogoutLabel>
          </Button>
        </MenuWrapper>
      )}
    </Wrapper>
  );
});

export default SettingsMenu;
