import React from 'react';

import { Wrapper, Image, Label } from './styled-components';

import rycoLogo from '../../../ryco.png';

const SenderHeader = ({ role, displayName, photoURL }) => {
  const isUser = role === 'user';
  return (
    <Wrapper>
      <Image src={isUser ? photoURL : rycoLogo} />
      <Label $isUser={isUser}>{isUser ? displayName : 'rybot'}</Label>
    </Wrapper>
  );
};

export default SenderHeader;
