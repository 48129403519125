import React from 'react';

import { Button } from './styled-components';

import { colors } from '../../../styles';

const SendMessageButton = ({ disabled, onClick }) => {
  return (
    <Button disabled={disabled} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M7 11L12 6L17 11M12 18V7"
          stroke={colors.white}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </Button>
  );
};

export default SendMessageButton;
