import React from 'react';

import {
  Wrapper,
  Modal,
  CloseButton,
  ProfileImage,
  Header,
  Description,
  UpgradeButton,
} from './styled-components';

import { month } from '../../services/TokenService/tokenService';

const LimitReached = ({ name, open, setOpen, photoURL, isTrial }) => {
  if (!open) return null;
  return (
    <Wrapper>
      <Modal>
        <ProfileImage src={photoURL} alt="current user" />
        <Header>👋 Hello {name} 👋</Header>
        {isTrial ? (
          <Description>
            Your trial has ended and you've used all available requests. We
            appreciate your use of rybot and hope you'll consider upgrading to
            continue using our service.
          </Description>
        ) : (
          <Description>
            {/* It seems you've reached the limit of messages for the month of {month}
          . We appreciate your use of rybot and hope you'll consider upgrading
          to a premium account to continue using our services. */}
            It seems you've reached the limit of messages for the month of{' '}
            {month}. We appreciate your use of rybot please use the contact
            button bellow to request more usage.
          </Description>
        )}
        <div>
          {/* <UpgradeButton onClick={() => setOpen(false)}>Upgrade</UpgradeButton> */}
          <UpgradeButton as="a" href="mailto:rybot@ryco.io">
            Contact
          </UpgradeButton>
          <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default LimitReached;
