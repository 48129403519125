import styled from 'styled-components';

import rycoLogo from '../../ryco.png';
import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    ${colors.darkPurple},
    ${colors.lightPurple} 100vh
  );
`;

export const Container = styled.div`
  border: 2px solid ${colors.lightPurple};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background-color: ${colors.white};
`;

export const Logo = styled.img.attrs({
  src: rycoLogo,
  alt: 'ryco logo',
})`
  width: 75px;
  height: 75px;
  justify-self: flex-start;
`;

export const Header = styled.h1`
  color: ${colors.lightPurple};
  text-align: center;
`;

export const SubHeader = styled.p`
  color: ${colors.black};
  text-align: center;
  margin-bottom: auto;
  max-width: 380px;
`;

export const LoginButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const WaitListButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  color: ${colors.lightPurple};
  text-decoration: underline;
  padding: 0;
  font-size: inherit;

  &:hover {
    color: ${colors.darkPurple};
  }
`;
